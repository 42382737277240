import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { OrderService } from 'src/app/services/order.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-order-auth',
  templateUrl: './order-auth.component.html',
  styleUrls: ['./order-auth.component.scss']
})

export class OrderAuthComponent implements OnInit {
  res: any;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _orderService: OrderService,
    private _spinnerService: SpinnerService,
  ) {

  }

  ngOnInit() {
    this._route.queryParams.subscribe(params => {
      this._orderService.access_hash = params['access_hash'];
    });
  }

  authAccessForm = new FormGroup({
    access_pass: new FormControl('', [])
  });

  authAccess(form_data: any) {
    if (isNaN(Number(form_data.access_pass)) || form_data.access_pass.length !== 6) {
      this._orderService.openSnackBar("Enter a valid number.", "error");
      return;
    }
    this._orderService.access_pass = form_data.access_pass
    const data: Object = {
      access_hash: this._orderService.access_hash,
      access_pass: this._orderService.access_pass
    };
    this._spinnerService.start();
    this._orderService.apiContractsESIMs(data).subscribe({
      next: (response) => {
        this.res = response;
        this._orderService.contractData = this.res.body;
        this._orderService.previousUrl = this._router.url;
        this._orderService.previousUrlFirst = this._orderService.previousUrl.match(/\/([^?]+)\?*/)[1];
        this._router.navigate(['/order_items']);
        this._spinnerService.stop();
      },
      error: (error) => {
        console.error('authAccess:', error);
        this._orderService.openSnackBar(error.error.message, "error");
        this._spinnerService.stop();
      }
    });
  }

}
