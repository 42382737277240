import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  contract_hash: string = '';
  access_hash: string = '';
  access_pass: string = '';
  contractData: any;
  numberOfAboutEID: number = 0;
  res: any;
  currentUrl: string = '';
  previousUrl: any;
  previousUrlFirst: any;

  constructor(
    private _httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private _sanitizer: DomSanitizer,
    private _spinnerService: SpinnerService,
  ) {}

  getSafeHtml(html: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  submitEID(i: number) {
    if (this.isInvalidEID(this.contractData.data.esims[i].tmp_eid)) {
      this.openSnackBar("Enter a valid number.", "error");
      return;
    }

    const data: Object = {
      contract: {
        id: this.contractData.data.contract.id,
        hash: this.contractData.data.contract.hash
      },
      esim: {
        id: this.contractData.data.esims[i].id,
        eid: this.contractData.data.esims[i].tmp_eid
      }
    }

    // 同じEID重複して登録させない
    // const esims_eids: any = this.contractData.data.esims.map((item: any) => item.eid);
    // console.log('esims_eids:', esims_eids);
    // if (esims_eids.includes(this.contractData.data.esims[i].tmp_eid)) {
    //   this.openSnackBar("The submitted EID has been entered.", "error");
    //   console.log('eid:', this.contractData.data.esims[i].eid);
    //   return
    // }

    this._spinnerService.start();

    this.apiSaveEID(data).subscribe({
      next: (response) => {
        this.res = response;
        if (this.res.status === 200 && this.res.body.result === true) {
          this.contractData.data.esims[i].eid = this.contractData.data.esims[i].tmp_eid;
          this.openSnackBar(this.res.body.message, "success");
        }
        this._spinnerService.stop();
      },
      error: (error) => {
        this.openSnackBar(error.error.message, "error");
        this._spinnerService.stop();
      }
    });
  }

  openSnackBar(message: any, add_class: string) {
    const cfg = new MatSnackBarConfig();
    cfg.panelClass = ['notify_snackbar', add_class];
    this._snackBar.open(message, 'OK', cfg);
  }

  apiContractsESIMs(data: Object): Observable<any> {
    return this._httpClient.post(`${environment.api_root}/api/v2/contracts/esims`, data, { observe: 'response' });
  }

  apiSaveEID(data: Object): Observable<any> {
    return this._httpClient.post(`${environment.api_root}/api/v2/esims/save_eid`, data, { observe: 'response' });
  }

  apiActivateESIM(data: Object): Observable<any> {
    return this._httpClient.post(`${environment.api_root}/api/v2/esims/activate`, data, { observe: 'response' });
  }

  private isInvalidEID(input_eid: string): boolean {
    if (isNaN(Number(input_eid)) || input_eid === null || input_eid.length !== 32 || input_eid.startsWith('89') === false) {
      return true;
    }
    return false;
  }

}
