import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-about-eid',
  templateUrl: './about-eid.component.html',
  styleUrls: ['./about-eid.component.scss']
})
export class AboutEidComponent implements OnInit {

  constructor(
    private _router: Router,
    public orderService: OrderService,
  ) {

  }

  ngOnInit() {
    this.orderService.numberOfAboutEID += 1;
    if (this.orderService.previousUrl) {
      this.orderService.previousUrlFirst = this.orderService.previousUrl.match(/\/([^?]+)\?*/)[1];
    }
    else {
      this.orderService.openSnackBar("Sorry, try again from the email link or by scanning the QR code.", "error");
    }
  }

  backSubmitEID() {
    const navigationExtras: NavigationExtras = {
      queryParams: {contract: this.orderService.contract_hash}
    };
    this._router.navigate(['/submit_eid'], navigationExtras);
  }
  
  backOrderAuth() {
    const navigationExtras: NavigationExtras = {
      queryParams: {access_hash: this.orderService.access_hash}
    };
    this._router.navigate(['/order_items']);
  }
}
