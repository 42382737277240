<div class="order-auth">
  <div class="heading">
    <h1>Sakura Mobile - eSIM Activation</h1>
    <p>Please enter the 6-digit Access Code that was included in the shipping email when you entered your device's EID information.</p>
    <br/>
  </div>
  <form [formGroup]="authAccessForm" (ngSubmit)="authAccess(authAccessForm.value)" class="form">
    <label class="label" for="access_pass">Access code:</label>
    <input type="text" inputmode="numeric" maxlength="6" id="access_pass" class="input" tabindex="1" formControlName="access_pass">
    <button class="btn" type="submit" tabindex="2">Next</button>
  </form>
</div>