import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-eid',
  templateUrl: './input-eid.component.html',
  styleUrls: ['./input-eid.component.scss']
})

export class InputEidComponent {
  isFocused: boolean = false;
  @Input() esim!: any;
  @Input() index!: number;

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }
}
