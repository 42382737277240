import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {

  public activation_data: any;

  constructor(
    private dialogRef: MatDialogRef<QrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.activation_data = data;
  }

  ngOnInit(): void {

  }

  onClose() {
    this.dialogRef.close('close');
  }
}
