<div class="order-items" *ngIf="this.orderService.contractData">
  <div class="heading">
    <h1>Submit EIDs: {{ this.orderService.contractData.data.contract.code }}</h1>
    <div [innerHTML]="this.orderService.getSafeHtml(this.orderService.contractData.data.contract.message)"></div>
  </div>

  <ul *ngIf="this.orderService.contractData.data.esims">
    <li *ngFor="let esim of this.orderService.contractData.data.esims; let i = index">
      <table>
        <tr>
          <td>eSIM {{ i + 1 }}:</td>
          <td [innerHTML]="this.orderService.getSafeHtml(esim.status_text)"></td>
        </tr>
        <ng-container *ngIf="esim.message">
          <tr>
            <td colspan="2">
              {{ esim.message }}
              {{ this.res.body.esim.message }}
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>Plan:</td>
          <td>{{ esim.item }}</td>
        </tr>
        <tr>
          <ng-container *ngIf="submittable(i) === false; else changeable">
            <td>EID:</td>
            <td>{{ esim.eid }}</td>
          </ng-container>
          <ng-template #changeable>
            <td class="input-eid-td" colspan="2">
              <app-input-eid [esim]="esim" [index]="i"></app-input-eid>
            </td>
          </ng-template>
        </tr>
      </table>
      <ng-container *ngIf="submittable(i) === true; else disable">
        <button class="btn btn-allowed" type="submit" tabindex="{{ 2*i+2 }}" (click)="this.orderService.submitEID(i)">
          <ng-container *ngIf="esim.eid === null">Submit EID</ng-container>
          <ng-container *ngIf="esim.eid !== null">Update EID</ng-container>
        </button>
      </ng-container>
      <ng-template #disable>
        <ng-container *ngIf="submittable(i) === false">
          <button class="btn btn-disabled" type="submit" tabindex="{{ 2*i+2 }}" aria-disabled="true">EID Submission Complete</button>
        </ng-container>
      </ng-template>
    </li>
  </ul>
  
  <ng-container *ngIf="this.displayContactUs">
    <div class="container pb">
      <p style="color: #d00;">If you will use the eSIM in a device with a different EID from the one above, please contact us immediately using the email below with the new EID.</p>
      <a href="mailto:contact@sakuramobile.jp">contact@sakuramobile.jp</a>
    </div>
  </ng-container>
  <br/>
</div>