import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutEidComponent } from './components/about-eid/about-eid.component';
import { OrderAuthComponent } from './components/order-auth/order-auth.component';
import { OrderItemsComponent } from './components/order-items/order-items.component';
import { SubmitEidComponent } from './components/submit-eid/submit-eid.component';

const routes: Routes = [
  { path: 'about_eid', component: AboutEidComponent},
  { path: 'order_auth', component: OrderAuthComponent },
  { path: 'order_items', component: OrderItemsComponent },
  { path: 'submit_eid', component: SubmitEidComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
