<div class="container">
  <div class="heading">
    <h1>About EID</h1>
  </div>
  <ng-container *ngIf="this.orderService.contractData">
    <div [innerHTML]="this.orderService.getSafeHtml(this.orderService.contractData.data.contract.message)"></div>
  </ng-container>

  <ng-container *ngIf="this.orderService.previousUrlFirst === 'submit_eid'; else order_auth">
    <button class="btn" type="submit" tabindex="1" (click)="backSubmitEID()">Next</button>
  </ng-container>
  <ng-template #order_auth>
    <ng-container *ngIf="this.orderService.previousUrlFirst === 'order_items'">
      <button class="btn" type="submit" tabindex="1" (click)="backOrderAuth()">Next</button>
    </ng-container>
  </ng-template>
</div>