import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-submit-eid',
  templateUrl: './submit-eid.component.html',
  styleUrls: ['./submit-eid.component.scss']
})

export class SubmitEidComponent implements OnInit {
  local_env: boolean = false;
  on_demand_until: number = 0;
  contract: any;
  esims: any;
  eid: any;
  tmp_eid: any;
  res: any;
  contractData: any;
  displayContactUs: boolean = false;
  timeout: ReturnType<typeof setTimeout> | undefined;
  isFocused: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    public orderService: OrderService,
  ) {

  }

  ngOnInit() {
    this.orderService.currentUrl = this._router.url;
    this._route.queryParams.subscribe(params => {
      this.orderService.contract_hash = params['contract'];
    });
    this.local_env = window.location.origin.includes('localhost:');
    const data: Object = { contract_hash: this.orderService.contract_hash };
    this.orderService.apiContractsESIMs(data).subscribe({
      next: (response) => {
        this.orderService.contractData = response.body;
        if (this.orderService.contractData.data.contract.has_all_eid === false && this.orderService.numberOfAboutEID === 0) {
          this.orderService.previousUrl = this.orderService.currentUrl;
          this._router.navigateByUrl('/about_eid');
        }
        this.on_demand_until = this.orderService.contractData.data.contract.on_demand_until;
        this.contract = this.orderService.contractData.data.contract;
        this.esims = this.orderService.contractData.data.esims;
        this.esims.forEach((esim: any) => {
          esim.tmp_eid = esim.eid;
          if (esim.status === null && this.on_demand_until < 0 && esim.eid !== null) {
            this.displayContactUs = true;
          }
        });

        if (this.local_env && this.on_demand_until > 0) { // テスト用: 5秒後にEIDの入力無効化
          this.timeout = setTimeout(() => {this.on_demand_until = -1}, 5000);
        }
        else if (Math.sign(this.on_demand_until) === -1) {
        }
        else { // 本番用: 前日18時にEIDの入力無効化
          this.timeout = setTimeout(() => {this.on_demand_until = -1}, this.on_demand_until*1000);
        }
      },
      error: (error) => {
        console.error('orderService.apiContractsESIM:', error);
      }
    });
  }

  ngOnDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  submittable(i: number): boolean {
    const esim = this.orderService.contractData.data.esims[i];
    if (['binding', 'bound', 'activated'].includes(esim.status)) {
      return false;
    }
    else if (esim.eid === null) {
      return true;
    }
    else if (esim.eid !== null && this.on_demand_until >= 0) {
      return true;
    }
    else {
      return false;
    }
  }
}
