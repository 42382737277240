<div class="qr-code">
  <div class="inner">
    <p>Installation manuals:</p>
    <div>
      <a mat-stroked-button color="basic" href="https://www.sakuramobile.jp/pdf/esim/long-esim-activation-iphone.pdf" target="_blank" rel="noopener">iOS</a>&nbsp;
      <a mat-stroked-button color="basic" href="https://www.sakuramobile.jp/pdf/esim/long-esim-activation-android.pdf" target="_blank" rel="noopener">Android</a>
    </div>
    <p style="font-weight: bold; color: #d00;">※ The eSIM cannot be used without completion of the APN settings.</p>
    <img src="/assets/images/esim-activation-qr.png">
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>