import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OrderAuthComponent } from './components/order-auth/order-auth.component';
import { OrderItemsComponent } from './components/order-items/order-items.component';
import { SpinnerComponent } from './components/share/spinner/spinner.component';
import { SubmitEidComponent } from './components/submit-eid/submit-eid.component';
import { AboutEidComponent } from './components/about-eid/about-eid.component';
import { QrCodeComponent } from './components/dialogs/qr-code/qr-code.component';
import { InputEidComponent } from './components/share/input-eid/input-eid.component';

@NgModule({
  declarations: [
    AppComponent,
    OrderAuthComponent,
    OrderItemsComponent,
    SpinnerComponent,
    SubmitEidComponent,
    AboutEidComponent,
    QrCodeComponent,
    InputEidComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
