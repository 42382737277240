<div class="order-items">
  <ng-container *ngIf="this.orderService.contractData; else tryAgain">
    <div class="heading">
      <h1>eSIM Activation: {{ this.orderService.contractData.data.contract.code || 404 }}</h1>
      <div [innerHTML]="this.orderService.getSafeHtml(this.orderService.contractData.data.contract.message)"></div>
    </div>
  </ng-container>
  <ng-template #tryAgain>
    <div class="heading">
      <h1>Sorry, try again by scanning the QR code.</h1>
    </div>
  </ng-template>

  <ul *ngIf="this.orderService.contractData" class="items">
    <li *ngFor="let esim of this.orderService.contractData.data.esims; let i = index" class="item">
      <table>
        <tr>
          <td>eSIM {{ i + 1 }}:</td>
          <td [innerHTML]="this.orderService.getSafeHtml(esim.status_text)"></td>
        </tr>
        <ng-container *ngIf="esim.message">
          <tr>
            <td colspan="2">
              {{ esim.message }}<br>
              {{ this.res.body.esim.message }}
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>Plan:</td>
          <td>{{ esim.item }}</td>
        </tr>
        <tr>
          <ng-container *ngIf="esim.eid; else changeable">
            <td>EID:</td>
            <td>{{ esim.eid }}</td>
          </ng-container>
          <ng-template #changeable>
            <td class="input-eid-td" colspan="2">
              <app-input-eid [esim]="esim" [index]="i"></app-input-eid>
            </td>
          </ng-template>
        </tr>
      </table>

      <ng-container *ngIf="esim.status === 'bound' && esim.after_start_at === false; else maintenance">
        <button class="btn btn-disabled" type="submit" tabindex="{{ 2*i+2 }}" aria-disabled="true">Activate this eSIM (from {{esim.from}})</button>
        <ul class="annotations">
          <li class="annotation">
            This eSIM is can be activated from the contract start date of {{ esim.from }}. Please press the "Activate this eSIM" after the contract start date.
          </li>
          <li class="annotation" *ngIf="esim.regular_maintenance.is_start_date === true">
            [eSIM Activation System Maintenance] Tuesday 10PM to Wednesday 9AM (JST)
            During this timeframe, eSIMs cannot be activated. Normal eSIM usage is unaffected.
          </li>
        </ul>
      </ng-container>
      <ng-template #maintenance>
        <ng-container *ngIf="esim.status === 'bound' && esim.regular_maintenance.is_schedule === true; else after_start_at">
          <button class="btn btn-disabled" type="submit" tabindex="{{ 2*i+2 }}" aria-disabled="true">Activate this eSIM</button>
          <ul class="annotations">
            <li class="annotation">
              [eSIM Activation System Maintenance] Tuesday 10PM to Wednesday 9AM (JST)
              During this timeframe, eSIMs cannot be activated. Normal eSIM usage is unaffected.
            </li>
          </ul>
        </ng-container>
        <ng-template #after_start_at>
          <ng-container *ngIf="esim.status === 'bound'; else activated">
            <button class="btn btn-allowed" type="submit" tabindex="{{ 2*i+2 }}" (click)="activateESIM(i)">Activate this eSIM</button>
            <ul class="annotations" *ngIf="esim.regular_maintenance.is_start_date === true">
              <li class="annotation">
                [eSIM Activation System Maintenance] Tuesday 10PM to Wednesday 9AM (JST)
                During this timeframe, eSIMs cannot be activated. Normal eSIM usage is unaffected.
              </li>
            </ul>
          </ng-container>
          <ng-template #activated>
            <ng-container *ngIf="esim.status === 'activated'; else binding">
              <button class="btn btn-allowed" type="submit" tabindex="{{ 2*i+2 }}" (click)="onShowQRCode()">Show Activation QR-Code</button>
            </ng-container>
            <ng-template #binding>
              <ng-container *ngIf="esim.status === 'binding' || (esim.status === null && esim.eid !== null); else empty_eid">
                <button class="btn btn-disabled" type="submit" tabindex="{{ 2*i+2 }}" aria-disabled="true">EID Submission Complete</button>
              </ng-container>
              <ng-template #empty_eid>
                <button class="btn btn-allowed" type="submit" tabindex="{{ 2*i+2 }}" (click)="this.orderService.submitEID(i)">Submit EID</button>
              </ng-template>
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-template>
    </li>
  </ul>
</div>