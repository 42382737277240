import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OrderService } from 'src/app/services/order.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QrCodeComponent } from '../dialogs/qr-code/qr-code.component';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})

export class OrderItemsComponent implements OnInit {
  esims: any;
  eid: any;
  res: any;
  on_demand_until: number = 0;
  timeout: ReturnType<typeof setTimeout> | undefined;

  constructor(
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    public orderService: OrderService,
    private _spinnerService: SpinnerService,
  ) {

  }

  ngOnInit() {
    if (this.orderService.contractData === undefined) {
      return
    }
    this.orderService.currentUrl = this._router.url;
    if (this.orderService.contractData.data.contract.has_all_eid === false && this.orderService.numberOfAboutEID === 0 && this.orderService.previousUrlFirst === 'order_auth') {
      this.orderService.previousUrl = this.orderService.currentUrl;
      this._router.navigateByUrl('/about_eid');
    }
    this.esims = this.orderService.contractData.data.esims;
    this.esims.forEach((esim: any) => {
      esim.tmp_eid = esim.eid;
    }); 
  }

  ngOnDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  activateESIM(i: number) {
    const apiActivateData: Object = {
      contract: {
        id: this.orderService.contractData.data.contract.id,
        hash: this.orderService.contractData.data.contract.hash
      },
      esim: {
        id: this.orderService.contractData.data.esims[i].id
      }
    }
    this._spinnerService.start();
    this.orderService.apiActivateESIM(apiActivateData).subscribe({
      next: (response) => {
        this.res = response;
        if (this.res.status === 200 && this.res.body.result === true) {
          this.orderService.openSnackBar(this.res.body.message, "success");
        }
        this._spinnerService.stop();
        this.onShowQRCode();

        const apiContractData: Object = {
          access_hash: this.orderService.access_hash,
          access_pass: this.orderService.access_pass
        };

        this.timeout = setTimeout(() => {
          this.orderService.apiContractsESIMs(apiContractData).subscribe({
            next: (response) => {
              this.orderService.res = response;
              this.orderService.contractData = this.orderService.res.body;
            },
            error: (error) => {
              console.error('activateESIM:orderService.apiActivateESIM::', error);
            }
          });
        }, 1000);
      },
      error: (error) => {
        console.error('activateESIM:orderService.apiActivateESIM::', error);
        this.orderService.openSnackBar(error.error.message, "error");
        this._spinnerService.stop();
      }
    })
  }

  onShowQRCode() {
    const dialogConfig = new MatDialogConfig;
    dialogConfig.autoFocus = false;
    const dialogRef = this._dialog.open(QrCodeComponent, {...dialogConfig});
  }
}